<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">창고</div>
            <ul class="content">
              <li class="item">
                <ejs-multiselect ref="wrhous" v-model="searchFilterOptions.wrhous"
                                 :dataSource="commonCodeOptions.wrhousData" :fields="commonCodeOptions.fields"
                                 :allowFiltering="false" cssClass="lookup-condition-multiselect" width="300"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">상품명</div>
            <ul class="content">
              <li class="item">
                <input-text v-model="searchFilterOptions.productName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title"></div>
            <div class="title">재고유무</div>
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <input type="checkbox" v-model="searchFilterOptions.isStock"/>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" @click.native = "onClickSearch()">조회</erp-button>
        </div>
      </div>
      <div class="lookup-right"> <!-- 사용시 좌측이 없을 경우 생략-->
        <ul class="lookup-button">  </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{ gridField.title }}</div>
              <div class="header-caption">[{{gridField.count}}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print"><erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button></li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="grid"
                v-bind ="initGrid"
                @actionComplete="gridComplete"
            />
          </div>
        </section>
      </article>
    </div>
    <!-- 각종팝업 -->
  </div>
</template>
<style scoped>

</style>

<script>

import inputText from "@/components/common/text/InputText.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import StockCommon from "@/api/v2/StockManage/StockCommon";
import StockProductStat from "@/api/v2/StockManage/StockProductStat";

export default {
  name: "ProductStockStatus",
  components: {ejsGridWrapper, ErpButton, inputText},
  created() {
  },
  mounted() {
    this.initData();
  },
  data() {
    return {
      searchFilterOptions: {
        productName: null,
        wrhous: [],
        wrhousList:null,  //다중선택된 창고코드 조회값
        isStock: false,
      },
      //드롭다운 코드
      commonCodeOptions: {
        wrhousData: [],
        fields: {text: "comName", value: "comCode"},
        //공통코드 들을 받아오는 필드
      },
      // 그리드 데이타
      gridField:{
        title: "재고 목록",
        count : 0,
        dataSource: [],
      },
    };
  },

  computed: {
    //그리드 해더 세팅
    initGrid(){
      return{
        columns:  [
          { field: 'pathDesc',    textAlign: 'left',   width: 150, visible: true,   allowEditing : false,  type: 'string',    headerText: '분류',    },
          { field: 'productName', textAlign: 'left',   width: 100, visible: true,   allowEditing : false,  type: 'string',    headerText: '상품명',    },
          { field: 'productCode', textAlign: 'center', width: 60,  visible: true,   allowEditing : false,  type: 'string',    headerText: '상품코드', },
          { field: 'wrhousName',  textAlign: 'left',   width: 250, visible: true,   allowEditing : false,  type: 'string',    headerText: '창고',    },
          { field: 'stockQty',    textAlign: 'right',  width: 60,  visible: true,   allowEditing : false,  type: 'string',    headerText: '재고수량', isNumericType: true,},
          { field: 'unit',        textAlign: 'left',   width: 60,  visible: true,   allowEditing : false,  type: 'string',    headerText: '단위',    },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: false,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: {pageSize: 50},
      };
    },
  },
  methods: {
    //초기화
    async initData() {
      //창고콤보(권한있는 상품창고만) -- 현재고므로 현재 사용 창고만
      this.commonCodeOptions.wrhousData  = await StockCommon.getWrhous({useFlag:'Y',authoFlag:'N',suppliesFlag:'N'});
    },
    //region ============================ API ==========================================
    async apiGetList() {
      // 조회조건에 있는 창고만(권한창고)
      const  wrhousList = this.searchFilterOptions.wrhous.join('|');
      this.searchFilterOptions.wrhousList = wrhousList === "" ? this.commonCodeOptions.wrhousData.map(data => data.comCode).join('|') : wrhousList ;
      // 창고 array 객체 삭제.
      const args = Object.assign({...this.searchFilterOptions},{wrhous:null});
      this.gridField.dataSource = await StockProductStat.getProductStockList(args);
    },
    //endregion ========================================================================
    //region ============================ Button Click ==========================================
    onClickSearch() {
      this.apiGetList();
    },
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title + ".xlsx"});
    },
    //endregion ========================================================================
    //region ============================ 그리드 ==========================================
    //그리드 조회 완료시(카운트)
    gridComplete() {
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //endregion ========================================================================
  }
};
</script>
